import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { Session, SessionProduct } from 'types/Session';

const api = process.env.REACT_APP_API_URL;

export const makeGetSessionProductsRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<SessionProduct>>(
    `${api}/session-products?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`,
  );

export const makePostSessionProductRequest = (form: FormData) => axios.post<Session>(`${api}/session-products`, form);

export const makePutSessionProductRequest = (id: number) => (form: FormData) =>
  axios.post<void>(`${api}/session-products/${id}`, form);

export const makeDeleteSessionProductRequest = (id: number) => axios.delete<void>(`${api}/session-products/${id}`);
