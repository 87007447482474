import PanelLayout from 'components/PanelLayout/PanelLayout';
import { Route, Routes } from 'react-router-dom';
import actionPanelAdminMenuItems from 'core/menu/actionPanelAdminMenuItems';
import Users from '../shared/Users/Users';
import Messages from '../shared/Messages/Messages';
import AdminActions from './Actions/Actions';
import AdminProductAction from './Product/Product';
import AdminBaners from './Baners/Baners';
import AdminActionOrders from './Orders/Orders';
import ActionOrderArchives from '../../shared/actionPanel/OrderArchives/OrderArchives';
import { FormattedMessage } from 'react-intl';

const ActionPanel = () => {
  return (
    <Routes>
      <Route
        element={
          <PanelLayout
            linkPath="/"
            disabledMenu={false}
            menu={actionPanelAdminMenuItems}
            title={<FormattedMessage id="APP.ACTIONS_PANEL" />}
          />
        }
      >
        <Route path="/" element={<AdminActions />} />
        <Route path="/products" element={<AdminProductAction />} />
        <Route path="/users" element={<Users />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/orders" element={<AdminActionOrders />} />
        <Route path="/orders-archives" element={<ActionOrderArchives />} />
        <Route path="/baners" element={<AdminBaners />} />
      </Route>
    </Routes>
  );
};

export default ActionPanel;
