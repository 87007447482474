import React from 'react';
import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeader from 'components/PageHeader/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import Pagination from 'components/Pagination/Pagination';
import { useSnackbar } from 'notistack';
import { format, parseISO } from 'date-fns';
import { makeGetMessagesRequest, makePutMessageChangeStatusRequest } from 'core/services/message';
import { Message, MessageStatus } from 'types/Message';
import { MESSAGES } from 'core/Query';

const Messages = () => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('created_at');
  const orderQuery = useQuery([MESSAGES, page, sortValue], () => makeGetMessagesRequest(page, sortValue), {
    select(response) {
      return response.data;
    },
    onSuccess(data: Paginate<Message>) {
      if (data.data.length === 0 && page > 1) {
        setPage((old) => old - 1);
      }
    },
  });
  const { data, pages } = useCacheQuery({ data: orderQuery.data?.data, total: orderQuery.data?.total });
  const mutatuinChangeStatus = useMutation(makePutMessageChangeStatusRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'MESSAGE.CHANGE_STATUS.SUCCESS' }), { variant: 'success' });
    },
    onError(error) {
      enqueueSnackbar(intl.formatMessage({ id: 'MESSAGE.CHANGE_STATUS.ERROR' }), { variant: 'error' });
    },
  });

  const handleChange = (event: SelectChangeEvent<MessageStatus>, id: number) => {
    mutatuinChangeStatus.mutate({ id, status: event.target.value });
  };

  return (
    <Grid container spacing={2}>
      {(orderQuery.isLoading || orderQuery.isRefetching) && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[
          { value: 'created_at', name: <FormattedMessage id="SORT_BY.CREATED_DATE" /> },
          { value: 'status', name: <FormattedMessage id="SORT_BY.STATUS" /> },
        ]}
      >
        <FormattedMessage id="MESSAGES.TITLE" />
      </PageHeader>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Od</TableCell>
                  <TableCell width="40%">Treść</TableCell>
                  <TableCell width="20%">Status</TableCell>
                  <TableCell width="20%">Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((single) => (
                  <TableRow key={single.id}>
                    <TableCell>
                      <Typography variant="body2">{single.user.roleId === 2 ? 'KAM-MT' : 'Dystrybutor'}</Typography>
                      <Typography variant="body2">{single.user.name}</Typography>
                      <Typography variant="body2">{single.user.company}</Typography>
                    </TableCell>
                    <TableCell>{single.message}</TableCell>
                    <TableCell>
                      <Select
                        defaultValue={single.status}
                        disabled={mutatuinChangeStatus.isLoading}
                        size="small"
                        onChange={(event) => handleChange(event, single.id)}
                      >
                        <MenuItem value={MessageStatus.UN_READ}>Nieodczytana</MenuItem>
                        <MenuItem value={MessageStatus.READ}>Odczytana</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>{format(parseISO(single.createdAt), 'yyyy-MM-dd')}</TableCell>
                  </TableRow>
                ))}
                {orderQuery.isFetched && data?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <FormattedMessage id="MESSAGES.EMPTY" />
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      {data && <Pagination page={page} onChangePage={setPage} pages={pages} />}
    </Grid>
  );
};

export default Messages;
