import { Grid, Paper, Typography, TextField, Stack, CircularProgress, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTimePicker } from '@mui/lab';
import { AdminSessionFormProps, AdminSessionFormValues, AdminSessionSendForm } from './Form.types';
import { useFormik } from 'formik';
import { object, date, string, array, ref, number } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation, useQueryClient } from 'react-query';
import {
  makeGetDownloadForSessionRequest,
  makePostSessionRequest,
  makePutSessionRequest,
} from 'core/services/sessions';
import { useSnackbar } from 'notistack';
import { isAfter, isBefore } from 'date-fns';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SelectList from 'components/SelectList/SelectList';
import { ADMIN_SESSIONS } from 'core/Query';
import fileDownload from 'js-file-download';

const validationSchema = object().shape({
  name: string().required('ADMIN_SESSION_FORM.NAME.ERROR.REQUIRED').max(255, 'ADMIN_SESSION_FORM.NAME.ERROR.MAX'),
  startDate: date()
    .typeError('ADMIN_SESSION_FORM.START_DATE.ERROR.TYPE')
    .max(ref('endDate'), 'ADMIN_SESSION_FORM.START_DATE.ERROR.MAX'),
  endDate: date()
    .typeError('ADMIN_SESSION_FORM.END_DATE.ERROR.TYPE')
    .min(ref('startDate'), 'ADMIN_SESSION_FORM.END_DATE.ERROR.MIN'),
  description: string()
    .required('ADMIN_SESSION_FORM.DESCRIPTION.ERROR.REQUIRED')
    .max(500, 'ADMIN_SESSION_FORM.DESCRIPTION.ERROR.MAX'),
  selectedUsers: array()
    .of(
      object().shape({
        id: number(),
        balance: number().nullable().min(1, 'ADMIN_SESSION_FORM.SELECTED_USERS.ERROR.ARRAY'),
      }),
    )
    .min(1, 'ADMIN_SESSION_FORM.SELECTED_USERS.ERROR.REQUIRED'),
});

const getStatus = (startDate: Date | null, endDate: Date | null) => {
  if (!startDate || !endDate) return 'BAD';
  const now = new Date();
  if (isBefore(endDate, now)) return 'END';
  if (isAfter(startDate, now)) return 'WAIT';
  return 'DURING';
};

const AdminSessionForm = (props: AdminSessionFormProps) => {
  const { initialValues, sessionUser, userIsLoading, closeAdd, deleteIsLoading, handleDelete, edit } = props;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const id = initialValues.id;
  const showError = useShowError();
  const mutationPost = useMutation(makePostSessionRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_FORM.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(ADMIN_SESSIONS);
      if (closeAdd) closeAdd();
    },
    onError(error: any) {
      if (error.isAxiosError && error.response?.status === 422) {
        enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_FORM.ADD.ERROR.UNIQUE' }), { variant: 'error' });
        return;
      }
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_FORM.ADD.ERROR' }), { variant: 'error' });
    },
  });
  const mutationPut = useMutation(makePutSessionRequest(id as number), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_FORM.EDIT.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(ADMIN_SESSIONS);
    },
    onError(error: any) {
      if (error.isAxiosError && error.response?.status === 422) {
        enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_FORM.ADD.ERROR.UNIQUE' }), { variant: 'error' });
        return;
      }
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_FORM.EDIT.ERROR' }), { variant: 'error' });
    },
  });
  const handleSubmitSave = (form: AdminSessionFormValues) => {
    const submitForm: AdminSessionSendForm = {
      ...form,
      userIds: form.selectedUsers.map((single) => single.id),
    };
    if (id) {
      mutationPut.mutate(submitForm);
      return;
    }
    mutationPost.mutate(submitForm);
  };
  const formik = useFormik({ initialValues, onSubmit: handleSubmitSave, validationSchema });
  const mutationDownload = useMutation(makeGetDownloadForSessionRequest(id as number), {
    onSuccess(response) {
      fileDownload(response.data, `sesja-${formik.values.name}.xlsx`);
    },
  });
  const selectedUserError = showError('selectedUsers', formik, 'ADMIN_ACTION_FORM.SELECTED_USERS.ERROR.REQUIRED');
  const isLoading = mutationPost.isLoading || mutationPut.isLoading || deleteIsLoading;
  const saveLoading = mutationPost.isLoading || mutationPut.isLoading;
  return (
    <Grid item xs={12}>
      <Paper component="form" onSubmit={formik.handleSubmit}>
        <Grid container padding={1}>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              {edit ? (
                <IconButton disabled={isLoading} onClick={() => mutationDownload.mutate(undefined)}>
                  {mutationDownload.isLoading ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : (
                    <LocalPrintshopIcon />
                  )}
                </IconButton>
              ) : (
                <Typography variant="subtitle2" color="error">
                  <FormattedMessage id="COMMON.NOT_SAVE" />
                </Typography>
              )}
              <IconButton disabled={isLoading} onClick={() => handleDelete(id)}>
                {deleteIsLoading ? <CircularProgress size={20} color="secondary" /> : <DeleteIcon />}
              </IconButton>
              <IconButton disabled={isLoading} type="submit">
                {saveLoading ? <CircularProgress size={20} color="secondary" /> : <SaveIcon />}
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <Stack spacing={2}>
              <Typography variant="body2" color="inherit" sx={{ paddingBottom: '5px' }}>
                <FormattedMessage id="ADMIN_SESSION_FORM.STATUS" />{' '}
                <FormattedMessage
                  id={`ADMIN_SESSION_FORM.STATUS.${getStatus(formik.values.startDate, formik.values.endDate)}`}
                />
              </Typography>
              <TextField
                name="name"
                value={formik.values.name}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('name', formik))}
                helperText={showError('name', formik)}
                fullWidth
                label={<FormattedMessage id="ADMIN_SESSION_FORM.NAME" />}
              />
              <DateTimePicker
                value={formik.values.startDate}
                disabled={isLoading}
                inputFormat="yyyy-MM-dd HH:mm"
                maxDate={formik.values.endDate}
                mask="____-__-__ __:__"
                onChange={(value) => {
                  formik.setFieldValue('startDate', value);
                }}
                label={<FormattedMessage id="ADMIN_SESSION_FORM.START_DATE" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    name="startDate"
                    error={Boolean(showError('startDate', formik))}
                    helperText={showError('startDate', formik)}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <DateTimePicker
                value={formik.values.endDate}
                inputFormat="yyyy-MM-dd HH:mm"
                mask="____-__-__ __:__"
                disabled={isLoading}
                minDate={formik.values.startDate}
                onChange={(value) => {
                  formik.setFieldValue('endDate', value);
                }}
                label={<FormattedMessage id="ADMIN_SESSION_FORM.END_DATE" />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="endDate"
                    error={Boolean(showError('endDate', formik))}
                    helperText={showError('endDate', formik)}
                    fullWidth
                    onBlur={formik.handleBlur}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={3} padding={1}>
            <TextField
              name="description"
              value={formik.values.description}
              disabled={isLoading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(showError('description', formik))}
              helperText={showError('description', formik)}
              fullWidth
              multiline
              minRows={9}
              maxRows={9}
              label="Opis"
            />
          </Grid>
          <Grid item xs={12} md={5} padding={1}>
            <SelectList
              type="fied"
              title={<FormattedMessage id="ADMIN_SESSION_FORM.SESSION_USER" />}
              error={selectedUserError}
              height={250}
              optionsIsLoading={Boolean(userIsLoading)}
              disabled={isLoading}
              options={sessionUser?.map((single) => ({ ...single, balance: '' })) || []}
              selectedOptions={formik.values.selectedUsers}
              inputSetValue={formik.setFieldValue}
              inputHandleBlur={formik.handleBlur}
              fieldName="selectedUsers"
              innerFieldName="balance"
              errors={formik.errors.selectedUsers}
              onChange={(values) => formik.setFieldValue('selectedUsers', values)}
              emptyMessage={<FormattedMessage id="ADMIN_SESSION_FORM.NO_ADD_USERS" />}
              allMessage={<FormattedMessage id="ADMIN_SESSION_FORM.ALL_USERS" />}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AdminSessionForm;
