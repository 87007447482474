import React from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { Card, CardContent, Typography, TextField, Tooltip, Stack, Popper, Paper } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import InfoIcon from '@mui/icons-material/Info';
import { ProductProps } from './Product.types';
import { FormattedMessage } from 'react-intl';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Product = (props: ProductProps) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<number>(0);
  const [slide, setSlaid] = React.useState<number>(0);
  const inputRef = React.useRef<HTMLDivElement>(null);
  const { product, chooseAmount, orderedAmount } = props;
  const showTooltip = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    if (!open) return;
    const timeout = setTimeout(() => {
      setOpen(false);
    }, 2000);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);

  const showAmount = React.useMemo(() => {
    const baseAmount = product.amount - orderedAmount.all;
    const userAmount = product.users[0]?.pivot.amount ? product.users[0].pivot.amount : product.amount;
    const orderedUserAmount = userAmount - orderedAmount.user;
    const calcAmount = baseAmount > orderedUserAmount ? orderedUserAmount : baseAmount;
    const finalAMount = calcAmount - chooseAmount;
    return finalAMount > 0 ? finalAMount : 0;
  }, [product.amount, product.users, chooseAmount, orderedAmount]);

  const [, drag, preview] = useDrag(
    () => ({
      type: 'product',
      item: {
        id: product.id,
        name: product.name,
        image: product.images[0].url,
        shortDescription: product.shortDescription,
        price: product.price,
        amount: product.amount,
        selected: value,
      },
      canDrag: () => {
        if (value > 0 && value <= showAmount) {
          return true;
        }
        showTooltip();
        return false;
      },
    }),
    [value, product, showAmount],
  );

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);
  return (
    <Card
      ref={drag}
      sx={{
        backgroundColor: 'secondary.main',
        color: 'secondary.contrastText',
        width: '178px',
        minWidth: '178px',
        maxWidth: '178px',
        overflow: 'hidden',
        position: 'relative',
      }}
      onClick={() => setOpen(true)}
    >
      <AutoPlaySwipeableViews index={slide} onChangeIndex={(value) => setSlaid(value)} enableMouseEvents>
        {product.images.map((image) => (
          <div
            key={image.url}
            style={{ height: '202px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img src={image.url} alt="product" style={{ objectFit: 'cover', height: '100%', minWidth: '100%' }} />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Tooltip title={product.description}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#0F7160',
            color: '#FFFFFF',
            fontSize: '12px',
            padding: '16px 8px',
            cursor: 'pointer',
          }}
        >
          Info
        </div>
      </Tooltip>
      <CardContent sx={{ textAlign: 'center' }}>
        <Stack spacing={0.5} alignItems="center">
          <Typography variant="body1">{product.name}</Typography>
          <Typography variant="subtitle2">{product.shortDescription}</Typography>
          <Typography variant="h6">{product.price} pt</Typography>
          <TextField
            ref={inputRef}
            variant="outlined"
            inputProps={{ min: 0, max: showAmount }}
            value={value}
            onChange={(event) => {
              const newValue = parseInt(event.target.value, 10);
              if (isNaN(newValue)) return setValue(0);
              if (newValue > showAmount) return setValue(showAmount);
              if (newValue < 0) return setValue(0);
              setValue(newValue);
            }}
            size="small"
            type="number"
            onClick={(event) => {
              event.stopPropagation();
            }}
            sx={{ backgroundColor: '#fff', width: '70px', borderRadius: '4px' }}
          />
          <Popper open={open} anchorEl={inputRef.current}>
            <Paper sx={{ p: 1 }}>
              <Stack direction="row" spacing={1}>
                <InfoIcon color="secondary" />
                <Typography>Wprowadź poprawną ilość sztuk</Typography>
              </Stack>
            </Paper>
          </Popper>
          <Typography variant="subtitle2">
            {showAmount} <FormattedMessage id="PRODUCT.AVAILABLE" />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default Product;
