import { Menu } from './menu';

const actionPanelAdminMenuItems: Menu[] = [
  {
    title: 'ACTION_PANEL_ADMIN_MENU.ACTIONS',
    path: '/panel/actions/',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.ORDERS',
    path: '/panel/actions/orders',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.PRODUCTS',
    path: '/panel/actions/products',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.USERS',
    path: '/panel/actions/users',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.ORDERS_HISTORY',
    path: '/panel/actions/orders-archives',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.MESSAGES',
    path: '/panel/actions/messages',
  },
  {
    title: 'ORDER_PANEL_ADMIN_MENU.BANERS',
    path: '/panel/actions/baners',
  },
];

export default actionPanelAdminMenuItems;
