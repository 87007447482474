import applyCaseMiddleware from 'axios-case-converter';
import axios from 'axios';
import { isBackendError } from 'utils/backendError';

const useAxios = applyCaseMiddleware(axios.create(), { ignoreHeaders: true });

useAxios.interceptors.request.use((value) => {
  value.headers = {
    ...value.headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  };
  return value;
});

useAxios.interceptors.response.use(
  (value) => value,
  (error) => {
    if (isBackendError(error)) {
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/login';
      }
    }
    throw error;
  },
);

export default useAxios;
