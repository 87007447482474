import React from 'react';
import { Grid, Stack, Typography, Button } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import groupBy from 'lodash/groupBy';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { makeGetSessionRequest } from 'core/services/sessions';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import PageHeaderNoSort from 'components/PageHeaderNoSort/PageHeader';
import Product from 'components/Product/Product';
import { makeGetMyDistributorRequest, makeGetOrderedRequest } from 'core/services/user';
import DistributorCart from 'components/Cart/Cart';
import ProductDrop from 'components/Product/ProductDrop';
import { Order, SendOrder } from 'types/Order';
import { makePostSessionOrderRequest } from 'core/services/sessionOrders';
import { useSnackbar } from 'notistack';
import useTimer from 'hooks/useTimer';
import { KAMMT_ACTIVE_SESSION, MY_DISTRIBUTORS, SESSION_ASIGN_ORDER, SESSION_ASIGN_ORDER_ORDERED } from 'core/Query';
import useGlobalErrors from 'hooks/useGlobalErrors/useGlobalErrors';
import { isBackendError } from 'utils/backendError';

const KamMtSession = () => {
  const intl = useIntl();
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [details, setDetails] = React.useState<Record<number, string>>({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const { setErrors, globalFormatErrors, hasError } = useGlobalErrors();
  const [cartBalance, setCartBalance] = React.useState(0);
  const sessionQuery = useQuery([SESSION_ASIGN_ORDER, id], () => makeGetSessionRequest(id as string), {
    select(response) {
      return {
        ...response.data,
      };
    },
    onError() {
      setErrors(['KAMMT.SESSION.NOT_FOUND']);
    },
  });
  React.useEffect(() => {
    setErrors([]);
  }, [id, setErrors]);
  const distributorQuery = useQuery([MY_DISTRIBUTORS, id], () => makeGetMyDistributorRequest(id as string), {
    select(response) {
      return response.data;
    },
    onError() {
      setErrors(['KAMMT.SESSION.NOT_FOUND_DISTRIBUTOR']);
    },
    enabled: sessionQuery.isSuccess,
  });

  const orderedQuery = useQuery([SESSION_ASIGN_ORDER_ORDERED, id], () => makeGetOrderedRequest(id as string), {
    select(response) {
      return response.data;
    },
    onError() {
      setErrors(['KAMMT.SESSION.UNKNOWN_ERROR']);
    },
    enabled: sessionQuery.isSuccess,
  });
  const time = useTimer({ endDate: sessionQuery.data?.endDate });
  const addMutation = useMutation(makePostSessionOrderRequest(id as string), {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'KAMMT.SESSION.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.resetQueries([KAMMT_ACTIVE_SESSION]);
      navigate('/panel/sessions');
    },
    onError(error: unknown) {
      if (isBackendError(error)) {
        const statusIs400 = error.response?.status === 400;
        if (statusIs400) {
          enqueueSnackbar(intl.formatMessage({ id: `KAMMT.SESSION.${error.response?.data.toUpperCase()}` }), {
            variant: 'error',
          });
        }
      }
    },
  });

  const handleSend = () => {
    const sendOrders: SendOrder[] = [];
    Object.entries(groupBy(orders, 'userId')).forEach(([key, orders]) => {
      sendOrders.push({
        userId: key,
        details: details[parseInt(key)],
        products: orders.map((innerOrder) => ({
          id: innerOrder.product.id,
          amount: innerOrder.product.chooseAmount,
        })),
      });
    });
    addMutation.mutate(sendOrders);
  };
  const baseBalance: number = sessionQuery.data?.kmmtBalance ? sessionQuery.data?.kmmtBalance : -1;
  const showBalance = baseBalance - cartBalance;
  const handleAddOrder = (order: Order) => {
    const newOrderTotalPrice = order.product.price * order.product.chooseAmount;
    setCartBalance((old) => old + newOrderTotalPrice);
    setOrders((old) => {
      const orderExist = old.find((single) => single.userId === order.userId && single.product.id === order.product.id);
      if (orderExist) {
        const oldFilter = old.filter(
          (single) => single.userId !== order.userId || single.product.id !== order.product.id,
        );
        const addOrder: Order = {
          ...orderExist,
          product: {
            ...orderExist.product,
            chooseAmount: orderExist.product.chooseAmount + order.product.chooseAmount,
          },
        };
        return [...oldFilter, addOrder];
      }
      return [...old, order];
    });
  };

  const handleRemoveOrder = (order: Order) => {
    const totalProductPrice = order.product.price * order.product.chooseAmount;
    setCartBalance((old) => old - totalProductPrice);

    setOrders((old) => {
      const oldFilter = old.filter(
        (single) => single.userId !== order.userId || single.product.id !== order.product.id,
      );
      return oldFilter;
    });
  };

  if (hasError) return globalFormatErrors;
  const isLoading = sessionQuery.isLoading || distributorQuery.isLoading || orderedQuery.isLoading;
  return (
    <DndProvider backend={HTML5Backend}>
      {isLoading && <FullPageLoader />}
      {!isLoading && (
        <>
          <Grid container spacing={2}>
            <PageHeaderNoSort>
              <FormattedMessage id="KAM_MT_SESSION.TITLE" /> {time}
            </PageHeaderNoSort>
          </Grid>

          {baseBalance !== -1 ? (
            <Typography variant="h6">
              <FormattedMessage id="KAMMT.SESSION.ORDER.BALANCE" values={{ balance: showBalance }} />
            </Typography>
          ) : null}

          <PerfectScrollbar>
            <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
              {sessionQuery.data?.userProducts.map((product: any) => (
                <Product
                  key={product.id}
                  product={product}
                  chooseAmount={orders
                    .filter((order) => order.product.id === product.id)
                    .reduce((old, curr) => {
                      return old + curr.product.chooseAmount;
                    }, 0)}
                  orderedAmount={{
                    all: (orderedQuery.data && orderedQuery.data[product.id] && orderedQuery.data[product.id].all) ?? 0,
                    user:
                      (orderedQuery.data && orderedQuery.data[product.id] && orderedQuery.data[product.id].user) ?? 0,
                  }}
                />
              ))}
            </Stack>
          </PerfectScrollbar>
          <Stack direction="row" spacing={2} sx={{ mt: 4 }} flexWrap="wrap">
            {distributorQuery.data?.map((distributor) => (
              <DistributorCart
                key={distributor.id}
                id={distributor.id}
                name={distributor.name}
                dragProduct={handleAddOrder}
                removeProduct={handleRemoveOrder}
                orders={orders.filter((order) => order.userId === distributor.id)}
                details={details[distributor.id]}
                setDetails={setDetails}
              />
            ))}
          </Stack>
          <ProductDrop />
          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Typography variant="h6">
                  <FormattedMessage id="COMMON.ORDER.SUFFIX" />
                </Typography>
                <Button color="secondary" disabled={orders.length === 0} variant="contained" onClick={handleSend}>
                  <FormattedMessage id="COMMON.ORDER.BUTTON" />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </DndProvider>
  );
};

export default KamMtSession;
