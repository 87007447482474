import React from 'react';
import { Box, Collapse, Drawer, List, ListItemButton, ListItemText, SxProps, Toolbar, Typography } from '@mui/material';
import { Menu } from 'core/menu/menu';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DrawerProps } from './Drawer.types';

const MenuCollapse = ({ menu }: { menu: Menu }) => {
  const [open, setOpen] = React.useState<boolean>(true);
  return (
    <>
      <ListItemButton disabled={menu.disabled} onClick={() => setOpen(!open)}>
        <ListItemText>
          <FormattedMessage id={menu.title} values={menu.titleValues} />
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open}>
        <List disablePadding>
          {menu.submenu?.map((inner) => {
            return <MenuItem key={`${inner.title}-${inner.titleValues?.session}`} menu={inner} style={{ pl: 4 }} />;
          })}
        </List>
      </Collapse>
    </>
  );
};

const MenuItem = ({ menu, style }: { menu: Omit<Menu, 'submenu'>; style?: SxProps }) => {
  const resolved = useResolvedPath(menu.path);
  const match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();
  return (
    <ListItemButton disabled={menu.disabled} selected={Boolean(match)} onClick={() => navigate(menu.path)} sx={style}>
      <FormattedMessage id={menu.title} values={menu.titleValues} />
    </ListItemButton>
  );
};

const MenuComponent = ({ menu, isDesktop, open, onClose }: DrawerProps) => {
  const intl = useIntl();
  return (
    <Drawer
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={open}
      onClose={onClose}
      sx={{
        width: 270,
        '& .MuiDrawer-paper': {
          width: 270,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <List sx={{ flex: 1 }}>
        {menu.map((single) => {
          if (single.submenu) {
            return <MenuCollapse key={single.title} menu={single} />;
          }
          return <MenuItem key={single.title} menu={single} />;
        })}
      </List>
      <Box padding={1}>
        <Typography variant="subtitle2" color="textSecondary">
          <FormattedMessage id="APP_BAR.MENU.HELP.TITLE" />
        </Typography>
        <Typography
          component="a"
          href={`mailto:${intl.formatMessage({ id: 'APP_BAR.MENU.HELP.CONTENT' })}`}
          variant="body1"
          color="textPrimary"
        >
          <FormattedMessage id="APP_BAR.MENU.HELP.CONTENT" />
        </Typography>
      </Box>
    </Drawer>
  );
};

export default MenuComponent;
