import React from 'react';
import { Grid, Paper, TextField, Stack, CircularProgress, IconButton, Autocomplete, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { FormattedMessage, useIntl } from 'react-intl';
import { AdminSessionProductFormProps, AdminSessionProductFormValues } from './Form.types';
import { useFormik } from 'formik';
import { object, string, array, number } from 'yup';
import { useShowError } from 'hooks/useShowError';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { UploadFile } from 'antd/lib/upload/interface';
import { makePostSessionProductRequest, makePutSessionProductRequest } from 'core/services/sessionProducts';
import SelectList from 'components/SelectList/SelectList';
import { SESSION_PRODUCTS } from 'core/Query';
import Upload from 'components/Upload/Upload';

const validationSchema = (maxReduce: number) =>
  object().shape({
    name: string()
      .required('ADMIN_SESSION_PRODUCT_FORM.NAME.ERROR.REQUIRED')
      .max(100, 'ADMIN_SESSION_PRODUCT_FORM.NAME.ERROR.MAX'),
    shortDescription: string()
      .required('ADMIN_SESSION_PRODUCT_FORM.SHORT_DESCRIPTION.ERROR.REQUIRED')
      .max(100, 'ADMIN_SESSION_PRODUCT_FORM.SHORT_DESCRIPTION.ERROR.MAX'),
    description: string()
      .required('ADMIN_SESSION_PRODUCT_FORM.DESCRIPTION.ERROR.REQUIRED')
      .max(500, 'ADMIN_SESSION_PRODUCT_FORM.DESCRIPTION.ERROR.MAX'),
    price: string()
      .required('ADMIN_SESSION_PRODUCT_FORM.PRICE.ERROR.REQUIRED')
      .test('min', 'ADMIN_SESSION_PRODUCT_FORM.PRICE.ERROR.MIN', (value): boolean => {
        if (!value) return false;
        return parseFloat(value) > 0;
      })
      .test('max', 'ADMIN_SESSION_PRODUCT_FORM.PRICE.ERROR.MAX', (value): boolean => {
        if (!value) return false;
        return parseFloat(value) < 9999999;
      })
      .matches(/^\d{0,8}(\.\d{1,2})?$/, 'ADMIN_SESSION_PRODUCT_FORM.PRICE.ERROR.MATCHES'),
    session: object()
      .typeError('ADMIN_SESSION_PRODUCT_FORM.SESSION_ID.ERROR.TYPE')
      .required('ADMIN_SESSION_PRODUCT_FORM.SESSION_ID.ERROR.TYPE'),
    amount: number()
      .typeError('ADMIN_SESSION_PRODUCT_FORM.AMOUNT.ERROR.TYPE')
      .integer('ADMIN_SESSION_PRODUCT_FORM.AMOUNT.ERROR.INTIGER')
      .required('ADMIN_SESSION_PRODUCT_FORM.AMOUNT.ERROR.REQUIRED')
      .min(1, 'ADMIN_SESSION_PRODUCT_FORM.AMOUNT.ERROR.MIN')
      .max(9999999, 'ADMIN_SESSION_PRODUCT_FORM.AMOUNT.ERROR.MAX'),
    selectedUsers: array()
      .of(
        object().shape({
          id: number(),
          amount: number().nullable().min(1).max(maxReduce),
        }),
      )
      .min(1, 'ADMIN_SESSION_PRODUCT_FORM.SELECTED_USERS.ERROR.MIN'),
  });

const AdminSessionProductForm = (props: AdminSessionProductFormProps) => {
  const [imageError, setImageError] = React.useState<string | undefined>(undefined);
  const [amount, setAmount] = React.useState<number>(props.initialValues.amount || 0);
  const [images, setImages] = React.useState<any[]>(props.initialValues.images);
  const [addImages, setAddImages] = React.useState<UploadFile[]>([]);
  const [removeImages, setRemoveImages] = React.useState<number[]>([]);
  const { initialValues, sessions, sessionIsLoading, closeAdd, edit } = props;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const id = initialValues.id;
  const showError = useShowError();
  const mutationPost = useMutation(makePostSessionProductRequest, {
    onSuccess() {
      setRemoveImages([]);
      setAddImages([]);
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.ADD.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(SESSION_PRODUCTS);
      if (closeAdd) closeAdd();
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.ADD.ERROR' }), { variant: 'error' });
    },
  });
  const mutationPut = useMutation(makePutSessionProductRequest(id as number), {
    onSuccess() {
      setRemoveImages([]);
      setAddImages([]);
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.EDIT.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries(SESSION_PRODUCTS);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.EDIT.ERROR' }), { variant: 'error' });
    },
  });
  const handleSubmitSave = (form: AdminSessionProductFormValues) => {
    if (images.length === 0) {
      setImageError(intl.formatMessage({ id: 'ADMIN_SESSION_PRODUCT_FORM.IMAGE.ERROR.REQUIRED' }));
      return;
    }
    const formData = new FormData();
    formData.append('name', form.name);
    formData.append('shortDescription', form.shortDescription);
    formData.append('description', form.description);
    formData.append('price', String(form.price));
    formData.append('amount', String(form.amount));
    formData.append('sessionId', String(form.session?.id));
    addImages.forEach((single) => {
      if (!single.originFileObj) return;
      formData.append('addImages[]', single.originFileObj);
    });
    form.selectedUsers.forEach((single) => {
      formData.append(`selectedUsers[${single.id}]`, single.amount ? String(single.amount) : '');
    });
    removeImages.forEach((single) => {
      formData.append('removeImages[]', single as any);
    });
    if (id) {
      mutationPut.mutate(formData);
      return;
    }
    mutationPost.mutate(formData);
  };
  const formik = useFormik({ initialValues, onSubmit: handleSubmitSave, validationSchema: validationSchema(amount) });
  const selectedUserError = showError('selectedUsers', formik, 'ADMIN_SESSION_PRODUCT_FORM.SELECTED_USERS.ERROR.ARRAY');
  const isLoading = mutationPost.isLoading || mutationPut.isLoading || props.deleteLoading;
  const saveLoading = mutationPost.isLoading || mutationPut.isLoading;
  const selectSession = sessions.find((single) => single.id === formik.values.session?.id);

  return (
    <Grid item xs={12}>
      <Paper component="form" onSubmit={formik.handleSubmit}>
        <Grid container padding={1}>
          <Grid display="flex" justifyContent="flex-end" item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              {!edit && (
                <Typography variant="subtitle2" color="error">
                  <FormattedMessage id="COMMON.NOT_SAVE" />
                </Typography>
              )}
              <IconButton disabled={isLoading} onClick={() => props.handleDelete(id)}>
                {props.deleteLoading ? <CircularProgress size={20} color="secondary" /> : <DeleteIcon />}
              </IconButton>
              <IconButton disabled={isLoading} type="submit">
                {saveLoading ? <CircularProgress size={20} color="secondary" /> : <SaveIcon />}
              </IconButton>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} padding={1}>
            <Stack spacing={2}>
              <TextField
                name="name"
                value={formik.values.name}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('name', formik))}
                helperText={showError('name', formik)}
                label={<FormattedMessage id="ADMIN_SESSION_PRODUCT_FORM.FORM.NAME" />}
                fullWidth
              />
              <TextField
                name="shortDescription"
                value={formik.values.shortDescription}
                disabled={isLoading}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={Boolean(showError('shortDescription', formik))}
                helperText={showError('shortDescription', formik)}
                label={<FormattedMessage id="ADMIN_SESSION_PRODUCT_FORM.FORM.SHORT_DESCRIPTION" />}
                fullWidth
              />
            </Stack>
            <Grid container spacing={1} marginTop={1}>
              <Grid item xs={12} md={6}>
                <TextField
                  name="price"
                  type="number"
                  value={formik.values.price}
                  disabled={isLoading}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(showError('price', formik))}
                  helperText={showError('price', formik)}
                  label={<FormattedMessage id="ADMIN_SESSION_PRODUCT_FORM.FORM.PRICE" />}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name="amount"
                  type="number"
                  value={formik.values.amount}
                  disabled={isLoading}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setAmount(parseInt(event.target.value));
                  }}
                  onBlur={formik.handleBlur}
                  error={Boolean(showError('amount', formik))}
                  helperText={showError('amount', formik)}
                  label={<FormattedMessage id="ADMIN_SESSION_PRODUCT_FORM.FORM.AMOUNT" />}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} padding={1}>
            <TextField
              name="description"
              value={formik.values.description}
              disabled={isLoading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(showError('description', formik))}
              helperText={showError('description', formik)}
              fullWidth
              multiline
              minRows={8}
              maxRows={8}
              label="Opis"
            />
          </Grid>
          <Grid item xs={12} md={5} padding={1}>
            <Stack spacing={2}>
              <Autocomplete
                disablePortal
                disableClearable
                value={formik.values.session}
                options={sessions || []}
                loading={sessionIsLoading}
                openOnFocus
                loadingText="Trwa ładowanie"
                noOptionsText="Brak przypisanych użytkowników do sesji"
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => {
                  formik.setFieldValue('session', value);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="session"
                    label={<FormattedMessage id="ADMIN_SESSION_PRODUCT_FORM.SESSION" />}
                    error={Boolean(showError('session', formik))}
                    helperText={showError('session', formik)}
                    onBlur={formik.handleBlur}
                  />
                )}
              />
              <SelectList
                type="fied"
                title={<FormattedMessage id="ADMIN_SESSION_FORM.USERS" />}
                error={selectedUserError}
                height={145}
                optionsIsLoading={Boolean(sessionIsLoading)}
                disabled={isLoading}
                options={selectSession?.users.map((single) => ({ ...single, amount: '' })) || []}
                selectedOptions={formik.values.selectedUsers}
                inputSetValue={formik.setFieldValue}
                inputHandleBlur={formik.handleBlur}
                fieldName="selectedUsers"
                innerFieldName="amount"
                errors={formik.errors.selectedUsers}
                onChange={(values) => formik.setFieldValue('selectedUsers', values)}
                emptyMessage={<FormattedMessage id="ADMIN_SESSION_FORM.NO_ADD_USERS" />}
                allMessage={<FormattedMessage id="ADMIN_SESSION_FORM.ALL_USERS" />}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Upload
              images={images}
              setImages={setImages}
              setRemoveImages={setRemoveImages}
              setAddImages={setAddImages}
              error={imageError}
              setError={setImageError}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default AdminSessionProductForm;
