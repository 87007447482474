import { AdminSessionSendForm } from 'components/AdminSession/Form.types';
import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { Session, SessionForKamMt } from 'types/Session';

const api = process.env.REACT_APP_API_URL;

export const makeGetSessionsRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<Session>>(`${api}/sessions?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`);

export const makeGetSessionAllRequest = () => axios.get<Session[]>(`${api}/sessions/all`);

export const makeGetSessionRequest = (id: string | number) => axios.get<SessionForKamMt>(`${api}/session/${id}`);

export const makePostSessionRequest = (form: AdminSessionSendForm) => axios.post<Session>(`${api}/sessions`, form);

export const makePutSessionRequest = (id: number) => (form: AdminSessionSendForm) =>
  axios.put<Session>(`${api}/sessions/${id}`, form);

export const makeDeleteSessionRequest = (id: number) => axios.delete<void>(`${api}/sessions/${id}`);

export const makeGetSessionAllActiveRequest = () => axios.get<Session[]>(`${api}/sessions/all-active`);

export const makeGetDownloadSessionsRequest = () => axios.get(`${api}/export/session-orders`, { responseType: 'blob' });

export const makeGetDownloadForSessionRequest = (id: number) => () =>
  axios.get(`${api}/export/session-orders/${id}`, { responseType: 'blob' });
