import { AdminActionSendForm } from 'components/AdminActions/Form.types';
import { KammtActionSendForm } from 'components/KammtActions/Form.types';
import axios from 'core/clients/api';
import { Action, ActionForUser } from 'types/Action';
import { OrderList, OrderStatus, SendOrder } from 'types/Order';
import { Paginate } from 'types/Paginate';

const api = process.env.REACT_APP_API_URL;

export const makeGetActionsRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<Action>>(`${api}/actions?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`);

export const makeGetDownloadActionsRequest = () => axios.get(`${api}/export/action-orders`, { responseType: 'blob' });

export const makeGetActionAllRequest = () => axios.get<Action[]>(`${api}/actions-admin/all`);

export const makeGetActionRequest = (id: string | number) => axios.get<ActionForUser>(`${api}/actions/${id}`);

export const makePostActionRequest = (form: AdminActionSendForm) => axios.post<Action>(`${api}/actions`, form);

export const makePutActionRequest = (id: number) => (form: AdminActionSendForm) =>
  axios.put<Action>(`${api}/actions/${id}`, form);

export const makePutKammtActionRequest = (id: number) => (form: KammtActionSendForm) =>
  axios.put<Action>(`${api}/actions-kammt/${id}`, form);

export const makeDeleteActionRequest = (id: number) => axios.delete<void>(`${api}/actions/${id}`);

export const makeGetActionsAllActiveRequest = () => axios.get<Action[]>(`${api}/actions/all-active`);

export const makeGetOrderedActionRequest = (actionId: string) =>
  axios.get<Record<string, { all?: number; user?: number }>>(`${api}/actions/${actionId}/ordered`);

export const makePostActionOrderRequest = (id: string) => (order: SendOrder) =>
  axios.post<void>(`${api}/actions/${id}/order`, order);

export const makeGetActionOrdersRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<OrderList>>(`${api}/action-orders?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`);

export const makeDeleteActionOrderRequest = (id: number) => axios.delete<void>(`${api}/action-orders/${id}`);

export const makeDeleteActionOrderProductRequest = (params: { orderSession: number; id: number }) =>
  axios.delete<void>(`${api}/action-orders/${params.orderSession}/product/${params.id}`);

export const makePostActionOrderChangeStatusRequest = (params: { orderSession: number; status: OrderStatus }) =>
  axios.post<void>(`${api}/action-orders/${params.orderSession}/change-status`, { status: params.status });

export const makeGetDownloadForActionRequest = (id: number) => () =>
  axios.get(`${api}/export/action-orders/${id}`, { responseType: 'blob' });
