import { Lang } from './Lang';

export enum UserRole {
  AMIN = 1,
  KAM_MT,
  DISTRIBUTOR,
}

export interface User {
  id: number;
  name: string;
  email: string;
  roleId: UserRole;
  phone: string;
  street: string;
  houseNumber: string;
  flatNumber: string;
  zipCode: string;
  city: string;
  company: string;
  userId: number | null;
  lang: Lang;
}

export type SessionUser = Pick<User, 'name' | 'id'>;

export type ActionUser = Pick<User, 'name' | 'id'> & {
  balance: number | '';
};

export type SessionProductUser = SessionUser & {
  amount: number | '';
};

export type Me = Pick<User, 'email' | 'id' | 'name' | 'lang'> & {
  role: {
    id: UserRole;
  };
};

export interface UserFilterOptions {
  kamMt: string[];
  city: string[];
  company: string[];
}

export interface UserSelectedFilters {
  kamMt: string;
  city: string;
  company: string;
}
