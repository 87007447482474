import axios from 'core/clients/api';
import { Paginate } from 'types/Paginate';
import { ActionProduct } from 'types/Action';

const api = process.env.REACT_APP_API_URL;

export const makeGetActionProductsRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<ActionProduct>>(`${api}/action-products?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`);

export const makePostActionProductRequest = (form: FormData) =>
  axios.post<ActionProduct>(`${api}/action-products`, form);

export const makePutActionProductRequest = (id: number) => (form: FormData) =>
  axios.post<void>(`${api}/action-products/${id}`, form);

export const makeDeleteActionProductRequest = (id: number) => axios.delete<void>(`${api}/action-products/${id}`);
