import axios from 'core/clients/api';
import { OrderList, OrderStatus, SendOrder } from 'types/Order';
import { Paginate } from 'types/Paginate';

const api = process.env.REACT_APP_API_URL;

export const makePostSessionOrderRequest = (id: string) => (orders: SendOrder[]) =>
  axios.post<void>(`${api}/sessions/${id}/orders`, { orders });

export const makeGetSessionOrderRequest = (page: number, sortValue: string) =>
  axios.get<Paginate<OrderList>>(`${api}/sesions-orders?perPage=10&page=${page}&orderBy=${sortValue}&order=desc`);

export const makeDeleteSessionOrderRequest = (id: number) => axios.delete<void>(`${api}/sesions-orders/${id}`);

export const makeDeleteSessionOrderProductRequest = (params: { orderSession: number; id: number }) =>
  axios.delete<void>(`${api}/sesions-orders/${params.orderSession}/product/${params.id}`);

export const makePostSessionOrderChangeStatusRequest = (params: { orderSession: number; status: OrderStatus }) =>
  axios.post<void>(`${api}/sesions-orders/${params.orderSession}/change-status`, { status: params.status });
