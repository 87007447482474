import React from 'react';
import {
  Grid,
  Paper,
  Button,
  Pagination,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Autocomplete,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import uniqWith from 'lodash/uniqWith';
import UserForm from 'components/Users/Form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  makeDeleteUserRequest,
  makeGetUserFilterOptionsRequest,
  makeGetUsersKamMtRequest,
  makeGetUsersRequest,
} from 'core/services/user';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { Paginate } from 'types/Paginate';
import PageHeader from 'components/PageHeader/PageHeader';
import useCacheQuery from 'hooks/useCacheQuery';
import { User, UserSelectedFilters } from 'types/Users';
import { useSnackbar } from 'notistack';
import { USERS, USERS_ONLY_KAMMT, USERS_FILTERS_OPTIONS } from 'core/Query';

const Users = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [filters, setFilters] = React.useState<UserSelectedFilters>({ kamMt: '', company: '', city: '' });
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const [addSession, setAddSession] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const [sortValue, setSortValue] = React.useState<string>('created_at');
  const filterOptions = useQuery(USERS_FILTERS_OPTIONS, makeGetUserFilterOptionsRequest, {
    select(response) {
      const city = uniqWith(response.data.city.sort(), (a, b) => a.toLowerCase() === b.toLowerCase());
      const company = uniqWith(response.data.company.sort(), (a, b) => a.toLowerCase() === b.toLowerCase());
      const kamMt = uniqWith(response.data.kamMt.sort(), (a, b) => a.toLowerCase() === b.toLowerCase());
      return {
        city,
        company,
        kamMt,
      };
    },
  });
  const dataQuery = useQuery([USERS, page, sortValue, filters], () => makeGetUsersRequest(page, sortValue, filters), {
    select(response) {
      return response.data;
    },
    onSuccess(data: Paginate<User>) {
      if (data.data.length === 0 && page > 1) {
        setPage((old) => old - 1);
      }
    },
    refetchOnWindowFocus: false,
  });
  const { data, pages } = useCacheQuery({ data: dataQuery.data?.data, total: dataQuery.data?.total });

  const userQuery = useQuery(USERS_ONLY_KAMMT, makeGetUsersKamMtRequest, {
    select(response) {
      return response.data.data;
    },
    refetchOnWindowFocus: false,
  });

  const mutatuinDelete = useMutation(makeDeleteUserRequest, {
    onSuccess() {
      enqueueSnackbar(intl.formatMessage({ id: 'USERS.DELETE.SUCCESS' }), { variant: 'success' });
      queryClient.refetchQueries([USERS]);
      queryClient.refetchQueries(USERS_ONLY_KAMMT);
      queryClient.refetchQueries(USERS_FILTERS_OPTIONS);
      setDeleteId(null);
    },
    onError() {
      enqueueSnackbar(intl.formatMessage({ id: 'USERS.DELETE.ERROR' }), { variant: 'error' });
    },
  });

  const confirmDelete = () => {
    if (!deleteId) return;
    mutatuinDelete.mutate(deleteId);
  };

  const handleDelete = (id: number | undefined) => {
    if (!id) return setAddSession(false);
    return setDeleteId(id);
  };
  return (
    <Grid container spacing={2}>
      {dataQuery.isFetching && <FullPageLoader />}
      <PageHeader
        sortValue={sortValue}
        onChangeValue={setSortValue}
        sortItems={[{ value: 'created_at', name: <FormattedMessage id="SORT_BY.CREATED_DATE" /> }]}
      >
        <FormattedMessage id="USERS.TITLE" />
      </PageHeader>
      <Grid item xs={12}>
        <Paper sx={{ marginTop: 2, padding: 2, paddingTop: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                value={filters.company}
                options={filterOptions.data?.company || []}
                loading={filterOptions.isLoading}
                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="USERS.FORM.COMPANY" />} />}
                onChange={(_, value) => {
                  setFilters((old) => ({ ...old, company: value || '' }));
                  setPage(1);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                value={filters.kamMt}
                options={filterOptions.data?.kamMt || []}
                loading={filterOptions.isLoading}
                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="USERS.FORM.KAM_MT" />} />}
                onChange={(_, value) => {
                  setFilters((old) => ({ ...old, kamMt: value || '' }));
                  setPage(1);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                value={filters.city}
                options={filterOptions.data?.city || []}
                loading={filterOptions.isLoading}
                renderInput={(params) => <TextField {...params} label={<FormattedMessage id="USERS.FORM.CITY" />} />}
                onChange={(_, value) => {
                  setFilters((old) => ({ ...old, city: value || '' }));
                  setPage(1);
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Button color="secondary" disabled={addSession} variant="contained" onClick={() => setAddSession(true)}>
            <FormattedMessage id="USERS.ADD_BUTTON" />
          </Button>
        </Box>
      </Grid>
      {addSession && (
        <UserForm
          userIsLoading={userQuery.isLoading}
          initialValues={{
            id: undefined,
            company: '',
            name: '',
            roleId: '',
            email: '',
            phone: '',
            street: '',
            houseNumber: '',
            flatNumber: '',
            zipCode: '',
            city: '',
            userId: null,
            lang: 'pl',
          }}
          closeAdd={() => setAddSession(false)}
          deleteIsLoading={mutatuinDelete.isLoading}
          handleDelete={handleDelete}
          users={userQuery.data || []}
        />
      )}
      {data?.map((single) => (
        <UserForm
          key={single.id}
          userIsLoading={userQuery.isLoading}
          initialValues={single}
          deleteIsLoading={mutatuinDelete.isLoading}
          edit
          handleDelete={handleDelete}
          users={userQuery.data || []}
        />
      ))}
      {dataQuery.isFetched && data?.length === 0 ? (
        <Grid item xs={12}>
          <Paper>
            <Box display="flex" justifyContent="center" padding={2}>
              <FormattedMessage id="ADMIN.USERS.EMPTY" />
            </Box>
          </Paper>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Paper>
          <Box display="flex" justifyContent="center" padding={2}>
            <Pagination
              page={page}
              onChange={(_, page) => setPage(page)}
              count={pages}
              variant="outlined"
              color="secondary"
            />
          </Box>
        </Paper>
      </Grid>
      <Dialog
        open={Boolean(deleteId)}
        onClose={() => {
          if (mutatuinDelete.isLoading) return;
          setDeleteId(null);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="USERS.CONFIRM.DELETE" />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage id="USERS.CONFIRM.DELETE.DESCRIPTION" />
        </DialogContent>
        <DialogActions>
          <Button disabled={mutatuinDelete.isLoading} onClick={() => setDeleteId(null)}>
            <FormattedMessage id="CONFIRM.NO" />
          </Button>
          <Button color="secondary" disabled={mutatuinDelete.isLoading} variant="contained" onClick={confirmDelete}>
            <FormattedMessage id="CONFIRM.YES" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Users;
